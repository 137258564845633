import Routes from 'routing/routes'
import { useRoutes } from 'react-router-dom'
import './App.css'
import { AuthInit } from 'context/auth'
import { LocationInit } from 'context/location'

function App() {
  const routes = useRoutes(Routes())
  return (
    <div
    // className='h-[100vh] overflow-y-auto '
    >
      <AuthInit>
        <LocationInit>{routes}</LocationInit>
      </AuthInit>
    </div>
  )
}

export default App
