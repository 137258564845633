import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react'
import { io } from 'socket.io-client'
import * as authHelper from 'utils/auth-helper'
import Api from 'utils/api'
import Loader from 'components/loader'
import { useLocationInfo } from './location'
import { toast } from 'react-toastify'
import env from 'configuration'

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState()
  const socketRef = useRef(null)

  const saveAuth = (auth) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const signoutApi = async (currentUser) => {
    console.log("currentUser", currentUser)
    if(currentUser){
      const { response, error } = await Api('/auth/signout', 'post', {
        payload: {email: currentUser.email, sessionId: auth.session._id},
        notify: true,
      })    
    }
  }

  const initializeSocket = (userId) => {
    if (!socketRef.current) {
      socketRef.current = io(env.SERVER) // Replace with your server URL
    }

    // Register the user with their userId
    socketRef.current.emit('register', userId)

    // Listen for the logout event
    socketRef.current.on('logout', (data) => {
      toast.error("session expired");
      logout()
    })
  }

  const logout = () => {
    signoutApi(currentUser);
    if (socketRef.current) {
      socketRef.current.disconnect() // Disconnect the socket on logout
      socketRef.current = null
    }
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  useEffect(() => {
    if (auth && auth.token && currentUser?._id) {
      initializeSocket(currentUser._id)
    }

    // Cleanup on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect()
        socketRef.current = null
      }
    }
  }, [auth, currentUser])

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const { getLocations, getLocation } = useLocationInfo()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (didRequest.current != auth) {
          setShowSplashScreen(true)
          const { response } = await Api('/auth/verify_token', 'get')
          if (response) {
            setCurrentUser(response.data)
            const locations = await getLocations()
            const selected_church =
              response.data?.selected_church || locations[0]?.campaign_code

            await getLocation(selected_church)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      didRequest.current = auth
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [auth])

  return showSplashScreen ? (
    <div className='w-full h-[100vh] flex flex-col items-center justify-center'>
      <Loader />
    </div>
  ) : (
    <>{children}</>
  )
}

export { AuthProvider, AuthInit, useAuth }
