// import KPIIcon from 'icon/kpi'
import KPIIcon from 'icons/kpi'
import React from 'react'

function KPILead({ label, value, description }) {
  return (
    <div className='relative justify-between flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 border border-blue-gray-100 shadow-sm'>
      <div className='bg-clip-border -mt-4 mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-gray-900 to-gray-800 text-white shadow-gray-900/20 absolute grid h-12 w-12 place-items-center'>
        <KPIIcon color={'white'} />
      </div>
      <div className='p-4 text-right'>
        <p className='block antialiased font-sans text-sm leading-normal font-normal text-blue-gray-600'>
          {label}
        </p>
        <h4 className='block antialiased tracking-normal font-sans text-1xl font-semibold leading-snug text-blue-gray-900'>
          {value}
        </h4>
      </div>
      <div className='border-t border-blue-gray-50 p-2'>
        <p className='block antialiased px-2 font-sans text-base leading-relaxed font-normal text-blue-gray-600'>
          {description}
        </p>
        {/* <strong className='text-green-500 px-1'>Top Brands</strong>
          &nbsp;Star Bucks */}
      </div>
    </div>
  )
}

export default KPILead
