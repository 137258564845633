import React from 'react'
import { Link } from 'react-router-dom'

function AppLogo() {
  return (
    <Link to={'/'}>
      {/* <img src='' alt='logo' width={'30px'} /> */}
      Stay Loyal
    </Link>
  )
}

export default AppLogo
