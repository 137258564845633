import KPILead from 'components/KPIs/kpi-lead'
import { useLocationInfo } from 'context/location'
import React, { useState } from 'react'
import { CurrencyFormat } from 'utils'

function Dashboard() {
  const { location } = useLocationInfo()
  const [monthlySale, setMonthlySale] = useState({})
  const [campaignsGraph, setCampaignGraphs] = useState({})
  const [recentSale, setRecentSale] = useState()
  const [locationKPIs, setLocationKPIs] = useState({})

  const getPercent = ({ newNum = 0, oldNum = 0 }) => {
    const percentage = (((newNum - oldNum) / newNum) * 100).toFixed()

    if (percentage > 0) {
      return `+${percentage}%`
    }
    if (percentage < 0) {
      return `-${Math.abs(percentage)}%`
    }
    return `${percentage}%`
  }

  const getRevnueStatus = () => {
    const percent = getPercent({
      newNum: locationKPIs.total_amount_today,
      oldNum: locationKPIs.total_amount_yesterday,
    })

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parseInt(percent, 10))) return '-100%'
    return percent
  }
  return (
    <>
      <div className='grid gap-6 mt-10 mb-6  lg:grid-cols-2'>
        {console.log(locationKPIs, 'locationkpis')}
        <KPILead
          label='Top customers'
          value={CurrencyFormat({
            amount: locationKPIs.top_campaign_amount,
            currency: location.basic_info?.currency_code,
          })}
          description={'Top Customers'}
        />
        <KPILead
          label={'Recent customers'}
          value={locationKPIs.active_subscribers}
          description={
            <>
              <span className='text-green-500 font-semibold'>
                {locationKPIs.total_subscriber}
              </span>
              &nbsp; Recent Customers
            </>
          }
        />
        <KPILead
          label={'Subscription Numbers'}
          value={CurrencyFormat({
            amount: locationKPIs.total_amount_today,
            currency: location.basic_info?.currency_code,
          })}
          description={
            <>
              <span className='text-green-500 font-semibold'>
                {getRevnueStatus()}
              </span>
              &nbsp; Number of subscriptions
            </>
          }
        />
        <KPILead
          label={'Stamps'}
          value={CurrencyFormat({
            amount: locationKPIs.top_customer_spent,
            currency: location.basic_info?.currency_code,
          })}
          description={'Total Stamps'}
        />
      </div>
    </>
  )
}

export default Dashboard
