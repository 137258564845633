import React from 'react'

function KPIIcon({ color }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill={color || '#000000'}
      width='20px'
      height='20px'
      viewBox='0 0 64 64'
    >
      <g id='a' />

      <g id='b'>
        <path d='M55,54c0,.5522-.4473,1-1,1H11.0463l-.0033,.0005-.0025-.0005h-1.0405c-.5522,0-1-.4478-1-1V10c0-.5522,.4478-1,1-1s1,.4478,1,1V51.4163c1.0442-2.6009,2.7624-6.8499,3.7563-9.1404,3.3477-7.7139,4.8838-9.2637,6.2778-9.2637,1.4878,0,2.2383,1.3901,2.9004,2.6162,.333,.6172,.6777,1.2554,1.0493,1.605,.1846,.1738,.5576,.4546,.9927,.3623,.4648-.0957,.915-.5991,1.2686-1.4165,.1553-.3584,.4917-1.5493,.8813-2.9277,2.7988-9.9019,4.834-15.2676,7.5688-15.2676,2.6172,0,3.6709,3.8999,4.6016,7.3408,.3516,1.3018,.8838,3.2695,1.2471,3.6069,.125,.0947,.1895,.1045,.1904,.1045,.4932-.1274,1.3301-2.2295,1.7324-3.2393,.1689-.4263,.3369-.8472,.5059-1.2402,.1211-.2822,.2432-.5952,.374-.9287,1.1143-2.853,2.9805-7.6279,9.6533-7.6279,.5527,0,1,.4478,1,1s-.4473,1-1,1c-5.3076,0-6.6846,3.5239-7.791,6.356-.1387,.3555-.2695,.689-.3984,.9897-.1621,.3774-.3232,.7817-.4854,1.1904-.8232,2.0684-1.6016,4.022-3.0693,4.4282-.4492,.126-1.1504,.1484-1.9326-.4492-.8359-.6372-1.2666-2.1138-1.957-4.6689-.5537-2.0488-1.585-5.8623-2.6709-5.8623-.7998,.02-2.4277,2.4321-5.644,13.8115-.501,1.7725-.772,2.7188-.9702,3.1777-.7939,1.8364-1.9062,2.4175-2.6992,2.5811-.9658,.2002-1.9727-.1162-2.7681-.8638-.6074-.5713-1.0303-1.3545-1.4395-2.1118-.2954-.5474-.8457-1.5664-1.1401-1.5664-.2847,.126-1.4604,1.186-4.4429,8.0596-1.1007,2.5362-3.1069,7.5192-4.0715,9.9282H54c.5527,0,1,.4478,1,1Zm-1-30h-3c-.5527,0-1,.4478-1,1s.4473,1,1,1h3c.5527,0,1-.4478,1-1s-.4473-1-1-1Zm0,7h-3c-.5527,0-1,.4478-1,1s.4473,1,1,1h3c.5527,0,1-.4478,1-1s-.4473-1-1-1Zm0,7h-3c-.5527,0-1,.4478-1,1s.4473,1,1,1h3c.5527,0,1-.4478,1-1s-.4473-1-1-1Zm0,7h-3c-.5527,0-1,.4478-1,1s.4473,1,1,1h3c.5527,0,1-.4478,1-1s-.4473-1-1-1Z' />
      </g>
    </svg>
  )
}

export default KPIIcon
