import FooterTabs from 'components/footer/footer'
import Footer from 'components/footer/footer'
import Header from 'components/Header/header'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AppLayout() {
  return (
    <div>
      <Header />
      <main className='max-w-xl mx-auto px-4'>
        <Outlet />
      </main>
      {/* <FooterTabs /> */}
    </div>
  )
}

export default AppLayout
