import { toast } from 'react-toastify'
import Api from './api'

const showToastMessage = (message) => {
  toast(message, {
    position: toast.POSITION.TOP_CENTER,
  })
}

// const getPercent = ({ newNum = 0, oldNum = 0 }) => {
//   console.log("new ", newNum,oldNum);
//   const percentage = (((newNum - oldNum) / newNum) * 100).toFixed()

//   if (percentage > 0) {
//     return `+${percentage}%`
//   }
//   if (percentage < 0) {
//     return `-${Math.abs(percentage)}%`
//   }
//   return `${percentage}%`
// }


const getPercent = ({ newNum = 0, oldNum = 0 }) => {
  console.log("new ", newNum, oldNum);

  if (newNum === 0) {
    return oldNum === 0 ? "0%" : "-100%"; // If oldNum is also 0, return "0%", otherwise return "-100%"
  }

  const percentage = (((newNum - oldNum) / newNum) * 100).toFixed();

  if (percentage > 0) {
    return `+${percentage}%`;
  }
  if (percentage < 0) {
    return `-${Math.abs(percentage)}%`;
  }
  return `${percentage}%`;
}

const formateDate = (date) => {
  return new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

const formateTime = (date) => {
  return new Date(date).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })
}

const isEmptyJSON = (json) => JSON.stringify(json) === '{}'

const CurrencyFormat = ({ amount, currency }) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD', // Change this to your desired currency code
    minimumFractionDigits: 2,
  })
  return currencyFormatter.format(amount)
}

const UploadImage = ({ notify, file, desiredPath }) => {
  const form = new FormData()
  form.append('image', file, file.name)
  return Api('/utils/upload/image?desiredPath=' + desiredPath, 'post', {
    payload: form,
    notify,
  })
}

const getImageURL = (img) => {
  return typeof img == 'object' ? URL.createObjectURL(img) : img
}

export {
  showToastMessage,
  isEmptyJSON,
  formateDate,
  formateTime,
  CurrencyFormat,
  UploadImage,
  getImageURL,
  getPercent
}
