import React from 'react'
import Select from 'components/Select/select'
import ProfileButton from 'components/profile-button'
import { useLocationInfo } from 'context/location'
import { useAuth } from 'context/auth'
import Api from 'utils/api'
import { useNavigate } from 'react-router-dom'
import GraphIcon from 'icons/graph'
import { Link } from 'react-router-dom'


function Header() {
  const { locations, locationKey, saveLocationKey } = useLocationInfo()
  const { setCurrentUser } = useAuth()
  const navigate = useNavigate() // Correct usage of useNavigate

  const handleSelectLocation = async (e) => {
    const { value } = e.target
    const location = await locations.find((loc) => {
      return loc.campaign_code === value
    })

    console.log(value, location)
    if (!location) return
    const { response } = await Api('/user', 'put', {
      payload: { selected_church: location.campaign_code },
      notify: true,
    })
    if (response) {
      saveLocationKey(location.campaign_code)
      setCurrentUser(response.data)
    }
  }

  return (
    <div className='p-5'>
      <div className='flex justify-between items-center'>
        <div className=''>
         <h3 className='font-bold text-xl'><Link to="/">Stay Loyal</Link></h3>
        </div>
       
        <div className='flex justify-center space-x-5 items-center'>
          {/* <div
            onClick={() => navigate('/dashboard')}
            className='cursor-pointer hover:text-blue-500'
          >
            <GraphIcon />
          </div> */}
           <div className=''>
            <Select
              onSelect={handleSelectLocation}
              placeholder={'Select Location'}
              value={locationKey}
              options={locations.map((location) => ({
                label: location.name,
                value: location.campaign_code,
              }))}
            />
          </div>
          <ProfileButton />
        </div>
      </div>
    </div>
  )
}

export default Header
