import Header from 'components/Header/header-auth'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AuthLayout() {
  return (
    <div className='h-[100vh] overflow-y-auto'>
      <Header />
      <main className='w-full px-4'>
        <Outlet />
      </main>
    </div>
  )
}

export default AuthLayout
