import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import AppLayout from 'screens/AppLayout'
import Dashboard from 'screens/dashboard/dashboard'

const Profile = ComponentLoader(lazy(() => import('screens/Profile/profile')))

const Home = ComponentLoader(lazy(() => import('screens/Home/home')))
// const Scantab = ComponentLoader(
//   lazy(() => import('components/scannertab/scantab'))
// )
const Transactiontab = ComponentLoader(
  lazy(() => import('components/transactiontab/transactiontab'))
)
const Customertab = ComponentLoader(
  lazy(() => import('components/customertab/customertab'))
)

const CustomerViewTab = ComponentLoader(
  lazy(() => import('components/customertab/customer-view'))
)

const PublicRoutes = [
  {
    path: '',
    element: <AppLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <Home />,
      },
      {
        path: 'profile',
        element: (
          <div className='max-w-3xl mx-auto'>
            <Profile />
          </div>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <div className='max-w-3xl mx-auto'>
            <Dashboard />
          </div>
        ),
      },
      {
        path: 'scanner',
        element: (
          <div className='max-w-3xl mx-auto'>
            <Home />
          </div>
        ),
      },
      {
        path: 'customer',
        element: (
          <div className='max-w-3xl mx-auto'>
            <Customertab />
          </div>
        ),
      },
      {
        path: 'customerview',
        element: (
          <div className='max-w-3xl mx-auto'>
            <CustomerViewTab />
          </div>
        ),
      },
      {
        path: 'transactions',
        element: (
          <div className='max-w-3xl mx-auto'>
            <Transactiontab />
          </div>
        ),
      },
      {
        path: '*',
        element: <Navigate to={'/'} />,
      },
      {
        path: '',
        element: <Navigate to={'/'} />,
      },
    ],
  },
]

export default PublicRoutes
