import Loader from 'components/loader'
import React, {
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
} from 'react'
import Api from 'utils/api'
import * as authHelper from 'utils/auth-helper'
import { useAuth } from './auth'

const initLocationContextPropsState = {
  location: undefined,
  setLocation: () => {},
  locations: [],
  setLocations: () => {},
  getLocation: async (selected_campaign) => {},
  getLocations: async () => {},
}

const LocationContext = createContext(initLocationContextPropsState)

const useLocationInfo = () => {
  return useContext(LocationContext)
}

const LocationProvider = ({ children }) => {
  const [locationKey, setLocationKey] = useState(authHelper.getLocationKey())
  const [location, setLocation] = useState()
  const [locations, setLocations] = useState([])

  const getLocation = async (selected_campaign) => {
    const { response } = await Api(
      '/campaign?campaign_code=' + selected_campaign,
      'get'
    )

    console.log("response location", response);

    if (response) {
      setLocation(response.data)
      // setLocationKey(response.data?.api_key)
    } else setLocation({})
  }

  const getLocations = async () => {
    const { response } = await Api('/campaign/user_campaigns', 'get')
    if (response) {
      setLocations(response.data)
      return response.data || []
    } else setLocations([])
  }
  const saveLocationKey = (locationKey) => {
    setLocationKey(locationKey)
    if (locationKey) authHelper.setLocationKey(locationKey)
  }

  return (
    <LocationContext.Provider
      value={{
        locationKey,
        saveLocationKey,
        location,
        setLocation,
        locations,
        setLocations,
        getLocation,
        getLocations,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}

const LocationInit = ({ children }) => {
  const { auth, currentUser } = useAuth()
  const { locationKey, locations, saveLocationKey, setLocation } =
    useLocationInfo()
  const { logout } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const resetLocationKey = () => {
    const key = locations[0]?.api_key
    saveLocationKey(key)
  }

  useEffect(() => {
    const requestLocation = async () => {
      try {
        console.log(didRequest.current, locationKey)
        if (didRequest.current != locationKey) {
          setShowSplashScreen(true)
          const { response } = await Api('/auth/verify_church', 'get')
          if (response) {
            setLocation(response.data)
          }
          // else resetLocationKey()
        }
      } catch (error) {
        console.error(error)
        // resetLocationKey()
        // if (!didRequest.current) {
        // logout()
        // }
      } finally {
        setShowSplashScreen(false)
      }

      didRequest.current = locationKey
    }

    if (auth && locationKey) {
      requestLocation()
    } else {
      // logout()
      setShowSplashScreen(false)
    }
  }, [locationKey])

  useEffect(() => {
    if (auth && currentUser && !locationKey)
      saveLocationKey(currentUser.selected_church || locations[0].campaign_code)
  }, [auth])

  return showSplashScreen ? (
    <div className='w-full h-[100vh] flex flex-col items-center justify-center'>
      <Loader />
    </div>
  ) : (
    <>{children}</>
  )
}
export { LocationProvider, useLocationInfo, LocationInit }
